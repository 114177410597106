import { makeQuery } from '@/api/helpers/makeQuery'

const query = `
mutation Signup($email: String!, $password: String!) {
  users {
    signup(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
}`

async function signup(email, password) {
  return makeQuery({
    query,
    variables: { email, password },
    success: data => data.users.signup,
  })
}

export default signup
