<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-lg-center align-items-center">
      <b-col
        lg="6"
        md="6"
        sm="12"
        class="auth-bg px-2 p-5 login-form mx-auto"
      >
        <h1 class="text-center">Регистрация в VK analytics</h1>

        <!-- form -->
        <validation-observer ref="form">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="signup"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
                ref="emailInput"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="ex@mail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group label="Пароль" label-for="login-password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
            >
              Зарегистрироваться
            </b-button>
          </b-form>
        </validation-observer>

        <b-button
            class="mt-1"
            @click="$router.replace({ name: 'login' })"
            block
        >
          Обратно к логину
        </b-button>

      </b-col>
    </b-row>
  </div>
</template>

<script type="text/javascript">
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import signup from '@/api/users/signup';
import getAccountConnectionURL from '@/api/users/getAccountConnectionURL'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      userEmail: '',
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async signup() {
      let data
      try {
        const success = await this.$refs.form.validate()
        if (!success) {
          return
        }

        data = await signup(this.userEmail, this.password)
      } catch (error) {
        this.$refs.emailInput.setErrors([error.message])
      }

      try {
        useJwt.setToken(data.accessToken)
        useJwt.setRefreshToken(data.refreshToken)

        const me = await this.$store.dispatch("users/fetchCurrent")
        me.ability = [{
          action: 'manage',
          subject: 'all',
        }];
        localStorage.setItem('userData', JSON.stringify(me))
        this.$ability.update(me.ability)

        await this.$router.replace(getHomeRouteForLoggedInUser())

        location.href = await getAccountConnectionURL()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/pages/page-auth.scss';

  .left-side-circles {
    position: relative;
    overflow: hidden;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      width: 70vh;
      height: 70vh;
      filter: blur(260px);
    }

    &:before {
      left: 0;
      top: 0;
      transform: translate(-40%, -40%);
      background: #6456FF;
    }

    &:after {
      right: 0;
      bottom: 0;
      transform: translate(40%, 40%);
      background: #426FFF;
    }
  }

  .login-form {
    max-width: 450px;
  }
</style>
